import firebase from "firebase/app"
import moment from "moment"

export const checkUserState = async (uid: string) => {
  const userFirestore = await firebase
    .firestore()
    .collection("users")
    .doc(uid)
    .get()
  const userData = userFirestore.data()

  const accountsKeys = Object.keys(userData?.accounts)
  const accounts = userData
    ? accountsKeys.map((key) => ({
        ...userData.accounts[key],
      }))
    : []

  const hasMultipleAccounts = accounts.length > 1

  const currentDate = moment()
  const parsePasswordUpdatedAt = moment(userData?.passwordUpdatedAt?.seconds * 1000)
  const yearsDiff = currentDate.diff(parsePasswordUpdatedAt, 'years')

  if (accounts.every((acc) => acc.state === "INACTIVE")) {
    return hasMultipleAccounts ? "INACTIVE_ACCOUNTS" : "INACTIVE_ACCOUNT"
  } else if (accounts.every((acc) => acc.linkedState === "UNLINKED")) {
    return hasMultipleAccounts ? "UNLINKED_ACCOUNTS" : "UNLINKED_ACCOUNT"
  } else if (
    accounts.every(
      (acc) => acc.state === "INACTIVE" || acc.linkedState === "UNLINKED"
    )
  ) {
    return "UNLINKED_INACTIVE_ACCOUNTS"
  } else if(!userData?.passwordUpdatedAt || (yearsDiff >= 1 && currentDate.isAfter(moment('2023-11-14')))){
      return "PASSWORD_EXPIRED"
  }else {
    return "ACTIVE"
  }
}
