import { CircularProgress } from "@material-ui/core"

const RenderLoader = () => {
  return (
    <div
      className={"d-flex align-items-center justify-content-center"}
      style={{ height: "100vh" }}
    >
      <CircularProgress
        thickness={5}
        size={40}
        color="inherit"
        className="ml-3 primary-spinner"
      />
    </div>
  )
}

export default RenderLoader
