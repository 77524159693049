import {
  AUTH_FORM_RESET,
  AUTHENTICATE,
  LOGOUT,
  UNKNOWN,
  UPDATE_CHANGE_PASSWORD_FIELD,
  UPDATE_IS_ADMIN,
  UPDATE_LOGIN_FIELD,
} from "../constants/actionTypes"
import { AuthStatus } from "../components/authentication/utils/AuthStatus"

const mockDefault = {
  authenticated: AuthStatus.UNKNOWN,
  formEmail: "",
  password: "",
  email: "",
  displayName: "",
}

export default (state = mockDefault, action) => {
  switch (action.type) {
    case UPDATE_LOGIN_FIELD:
      return { ...state, [action.key]: action.value }
    case AUTHENTICATE:
      return Object.assign({}, state, {
        authenticated: AuthStatus.AUTHENTICATED,
        email: action.data.email,
        displayName: action.data.displayName,
      })
    case LOGOUT:
      return Object.assign({}, mockDefault, {
        authenticated: AuthStatus.UNAUTHORIZED,
      })
    case UNKNOWN:
      return Object.assign({}, state, { authenticated: AuthStatus.UNKNOWN })
    case AUTH_FORM_RESET:
      return mockDefault
    case UPDATE_CHANGE_PASSWORD_FIELD:
      return { ...state, [action.key]: action.value }
    case UPDATE_IS_ADMIN:
      return { ...state, isAccountAdmin: action.value }
    default:
      return state
  }
}
