import { createSlice } from "@reduxjs/toolkit"
import {
  CardMovement,
  ClaimReason,
  ClaimType,
  ConsumptionModalActions,
} from "../components/Card/interfaces/types"

export interface ClaimForm {
  disputeAmount: number
  disputeType: ClaimType
  reason: ClaimReason
  description: string
  cardPossession: boolean
  files?: {
    image_1: File | null
    image_2: File | null
    image_3: File | null
  }
}

interface InitialState {
  open: boolean
  modalAction: ConsumptionModalActions
  consumptionData: CardMovement | null
  claimForm: ClaimForm | null
  documentId: string
}

const initialState: InitialState = {
  open: false,
  modalAction: null,
  consumptionData: null,
  claimForm: null,
  documentId: "",
}

const consumptionModal = createSlice({
  name: "Consumption Modal",
  initialState,
  reducers: {
    resetConsumptionModal: () => {
      return initialState
    },
    updateConsumptionModal: (
      state,
      action: { payload: Partial<InitialState> }
    ) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { resetConsumptionModal, updateConsumptionModal } =
  consumptionModal.actions

export default consumptionModal.reducer
