import { createSlice } from "@reduxjs/toolkit"
import {
  AddressType,
  CardBenefitType,
  CardState,
  CardType,
  DeliveryState,
  Gender,
  IdentificationTypes,
  ModalAction,
  ModalType,
  TypeOfReceiver,
  DischargeReason,
  MaritalStatus,
  CardProductType,
  CardCosts,
} from "../components/Card/interfaces/types"
import { UserRole } from "../components/userManagement/interfaces/types"
import { DataTimeObj, Signer } from "../interfaces/types"

export interface Address {
  country: string
  locality: string
  city: string
  street: string
  number: string
  floor: string | null
  department: string | null
  zipCode: string
}

interface TaxIdentification {
  type: string
  number: string
}

interface UserData {
  documentType: IdentificationTypes
  documentNumber: string
  userId: string
  firstName: string
  lastName: string
  phoneNumber: string
  gender: Gender
  birthDate: string
  nationality: string
  taxIdentification: TaxIdentification
  address: Address
  role: UserRole | null
  email: string
  documentFront: string | null
  documentBack: string | null
  documentFrontFileName: string
  documentBackFileName: string
  pep: string
  tramitNumber: string
  selfieDNI: string | null
  selfieDNIFileName: string
  maritalStatus: MaritalStatus
  activityCategory: string
}

interface DeliveryAddress extends Address {
  addressType: AddressType | null
}

interface Delivery {
  address: DeliveryAddress | null
  state: DeliveryState | null
  shipmentTrackingLink: string
  shippingCompanyName: string
}

export interface CardData {
  id: null | string
  pendingRequestId: null | string
  cardInviteId: string
  pomeloCardId: string | null
  type: CardType | null
  state: CardState | null
  active: boolean
  canBeActivated: boolean
  amountLimit: {
    DIGITAL: number | null
    PHYSICAL: number | null
  }
  paymentsAbroad: {
    DIGITAL: boolean
    PHYSICAL: boolean
  }
  canExtractCash: {
    DIGITAL: boolean
    PHYSICAL: boolean
  }
  typeOfReceiver: TypeOfReceiver
  userData: UserData
  delivery: Delivery
  extendedBy: string
  blockingReason?: string
  dischargeReason?: DischargeReason
  dischargedBy?: string
  canceledBy?: string
  accountId: string
  lastFourDigits: string
  modifiedBy?: string | null
  expiresAt?: DataTimeObj
  modificatedData: {
    amountLimit: number | null
    paymentsAbroad: boolean | null
    canExtractCash: boolean | null
  } 
  creationDateTime: DataTimeObj
  accumulatedExpenses?: number
  signatureData?: {
    signers: Signer[]
    potentialSigners: Signer[]
    activeState: "ACTIVE" | "INACTIVE"
  }
  companyAddress: Address | null,
  benefitType: CardBenefitType
  onDeliveryProcess: boolean
  productType: CardProductType
  chargesWasProcessed?: boolean
  embossingCharges?: CardCosts // USD,
  shipmentCharges?: CardCosts // ARS,
  embossingConvertedCharges?: CardCosts // ARS
}

interface InitialState {
  open: boolean
  modalType: ModalType
  modalAction: ModalAction
  cardData: CardData
  prevAction: ModalAction
  formReceiverStep: number | null
  modalHeight: number | null
  dniModalExample: "FRONT" | "BACK" | "SELFIE" | null
}

export const cardModalInitialState: InitialState = {
  open: false,
  modalType: null,
  modalAction: null,
  prevAction: null,
  formReceiverStep: null,
  modalHeight: null,
  dniModalExample: null,
  cardData: {
    id: null,
    pendingRequestId: null,
    cardInviteId: "",
    pomeloCardId: null,
    type: null,
    state: null,
    active: false,
    canBeActivated: false,
    onDeliveryProcess: false,
    amountLimit: {
      DIGITAL: null,
      PHYSICAL: null,
    },
    paymentsAbroad: {
      DIGITAL: false,
      PHYSICAL: false,
    },
    canExtractCash: {
      DIGITAL: false,
      PHYSICAL: false,
    },
    typeOfReceiver: "ME",
    extendedBy: "",
    accountId: "",
    lastFourDigits: "",
    creationDateTime: {
      seconds: 0,
      nanoseconds: 0,
    },
    modificatedData: {
      paymentsAbroad: null,
      amountLimit: null,
      canExtractCash: null,
    },
    userData: {
      documentType: "DNI",
      documentNumber: "",
      documentFront: null,
      documentBack: null,
      documentFrontFileName: "",
      documentBackFileName: "",
      selfieDNI: null,
      selfieDNIFileName: "",
      maritalStatus: "SOLTERO",
      activityCategory: "",
      role: null,
      userId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      gender: "MALE",
      birthDate: "",
      nationality: "",
      email: "",
      pep: "",
      tramitNumber: "",
      taxIdentification: {
        type: "",
        number: "",
      },
      address: {
        country: "",
        locality: "",
        city: "",
        street: "",
        number: "",
        floor: "",
        department: "",
        zipCode: "",
      },
    },
    delivery: {
      shipmentTrackingLink: "",
      shippingCompanyName: "",
      address: {
        addressType: null,
        country: "",
        locality: "",
        city: "",
        street: "",
        number: "",
        floor: "",
        department: "",
        zipCode: "",
      },
      state: null,
    },
    companyAddress: null,
    benefitType: "NONE",
    productType: "PREPAID",
  },
}

const cardModal = createSlice({
  name: "Card Modal",
  initialState: cardModalInitialState,
  reducers: {
    resetCardModal: () => {
      return cardModalInitialState
    },
    updateCardModal: (state, action: { payload: Partial<InitialState> }) => {
      return { ...state, ...action.payload }
    },
    updateCardData: (state, action: { payload: Partial<CardData> }) => {
      return {
        ...state,
        cardData: {
          ...state.cardData,
          ...action.payload,
        },
      }
    },
  
  },
})

export const { resetCardModal, updateCardModal, updateCardData } =
  cardModal.actions

export default cardModal.reducer
