import {
  ModalAction,
  ModalData,
  ModalType,
} from "../components/ui/TwoFactorAuthentication/interfaces/types"
import {
  AuthConfigurations,
  AuthenticationDailyRecord,
  AuthenticationFlow,
  AuthenticationMethod,
  AuthenticationMethodRecord,
  AuthenticationMessagingLimit,
  AuthenticationState,
  Nullable,
} from "./../interfaces/types"
import { createSlice } from "@reduxjs/toolkit"

interface Modal {
  open: boolean
  action: ModalAction
  prevAction: ModalAction
  modalType: ModalType
  modalData: ModalData
}

interface InitialState {
  twoFactorAuthenticationState: AuthenticationState | "NOT-FOUND"
  requestId?: string
  authenticationMethodRecordId?: string
  authenticationDailyRecordId?: string
  authenticationFlow: AuthenticationFlow
  activeDailyAuthentication: boolean
  authenticationMethodRecord: AuthenticationMethodRecord | null
  authenthicationDailyRecord: AuthenticationDailyRecord | null
  authenticationMethod: AuthenticationMethod | null
  modal: Modal
  authConfiguration: AuthConfigurations
  authMessagingLimit: AuthenticationMessagingLimit | undefined | null
  initialRegistrationStarted: boolean
  initialRegistrationFinished?: boolean
}

const initialState: Nullable<InitialState> = {
  twoFactorAuthenticationState: null,
  requestId: null,
  authenticationMethodRecordId: null,
  authenticationDailyRecordId: null,
  authenticationFlow: null,
  authenticationMethodRecord: null,
  authenthicationDailyRecord: null,
  activeDailyAuthentication: false,
  authenticationMethod: null,
  modal: {
    open: false,
    action: null,
    prevAction: null,
    modalType: null,
    modalData: null,
  },
  authConfiguration: null,
  authMessagingLimit: undefined,
  initialRegistrationStarted: false,
  initialRegistrationFinished: false,
}

const twoFactorAuth = createSlice({
  name: "2FA",
  initialState,
  reducers: {
    update2FA: (state, action) => {
      return { ...state, ...action.payload }
    },
    reset2FA: () => {
      return initialState
    },
    updateAuthFlow: (state, action) => {
      return { ...state, authenticationFlow: action.payload }
    },
    updateAuthMethodRecord: (state, action) => {
      return { ...state, authenticationMethodRecord: action.payload }
    },
    updateAuthDailyRecord: (state, action) => {
      return { ...state, authenthicationDailyRecord: action.payload }
    },
    updateDailyAuthentication: (state, action) => {
      return { ...state, activeDailyAuthentication: action.payload }
    },
    updateAuthMethod: (state, action) => {
      return { ...state, authenticationMethod: action.payload }
    },
    updateModal: (state, action) => {
      return { ...state, modal: { ...state.modal, ...action.payload } }
    },
    updateAuthConfiguration: (state, action) => {
      return { ...state, authConfiguration: action.payload }
    },
    updateAuthMessagingLimit: (state, action) => {
      return { ...state, authMessagingLimit: action.payload }
    },
    updateInitialRegistrationStarted: (state, action) => {
      return { ...state, initialRegistrationStarted: action.payload }
    },
    updateInitialRegistrationFinished: (state, action) => {
      return { ...state, initialRegistrationFinished: action.payload }
    },
  },
})

export const {
  update2FA,
  reset2FA,
  updateAuthFlow,
  updateAuthMethodRecord,
  updateAuthDailyRecord,
  updateDailyAuthentication,
  updateAuthMethod,
  updateModal,
  updateAuthConfiguration,
  updateAuthMessagingLimit,
  updateInitialRegistrationStarted,
  updateInitialRegistrationFinished,
} = twoFactorAuth.actions

export default twoFactorAuth.reducer
