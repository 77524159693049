import { createSlice } from "@reduxjs/toolkit";

type CardId = string
export interface CardSensitiveData {
    showData: boolean;
    accessToken: string;
    requestId: string;
}

interface InitialState {
    showCardData: Record<CardId, CardSensitiveData>
}

const initialState: InitialState = {
    showCardData: {}
}

const cardSensitiveData = createSlice({
    name: "cardSensitiveData",
    initialState,
    reducers: {
        resetCardSensitiveData: () => {
            return initialState
        },
        updateCardSensitiveData: (state, action: { payload: Record<CardId, CardSensitiveData> }) => {
            return { ...state, showCardData: {...state.showCardData, ...action.payload}}
        },
    }
})

export const { resetCardSensitiveData, updateCardSensitiveData } =
  cardSensitiveData.actions

export default cardSensitiveData.reducer