import { createSlice } from "@reduxjs/toolkit"

interface InitialState {
  searchValue: string
}

const initialState: InitialState = {
  searchValue: "",
}

const userManagementSearch = createSlice({
  name: "user management search",
  initialState,
  reducers: {
    resetUserManagementSearch: () => {
      return initialState
    },
    updateUserManagementSearch: (state, action) => {
      state.searchValue = action.payload
    },
  },
})

export const { resetUserManagementSearch, updateUserManagementSearch } =
  userManagementSearch.actions

export default userManagementSearch.reducer
