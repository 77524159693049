import { createSlice } from "@reduxjs/toolkit"
import {
  OrderCurrency,
  OrderState,
  OrderModalActions,
  OrderType,
} from "../components/investments/interfaces/types"
import { DataTime } from "../interfaces/types"

interface InitialState {
  open: boolean
  modalAction: OrderModalActions | null
  data: {
    accountId: string | null
    amount: number | null
    currency: OrderCurrency
    fundId: string | null
    fundName: string | null
    orderType: OrderType | null
    settlementDate: DataTime | null
    state: OrderState | null
    creationDate: DataTime | null
    requestUser: { fullName: string; id: string } | null
    id: string | null
    shares: number | null
    shareValue: number | null
    amountAvailableForRedemption: number | null
    redeemAll?: boolean
    partiallyRedeem?: boolean
  }
}

const initialState: InitialState = {
  open: false,
  modalAction: null,
  data: {
    id: null,
    accountId: null,
    amount: null,
    currency: "ARS",
    fundId: null,
    fundName: null,
    orderType: null,
    settlementDate: null,
    state: null,
    creationDate: null,
    requestUser: null,
    shares: null,
    shareValue: null,
    amountAvailableForRedemption: null,
  },
}

const orderIntentionsModal = createSlice({
  name: "Order intentions Modal",
  initialState,
  reducers: {
    onUpdateOrderIntentionsModal: (
      state,
      action: { payload: Partial<InitialState> }
    ) => {
      return { ...state, ...action.payload }
    },
    onResetOrderIntentionsModal: () => {
      return initialState
    },
  },
})

export const { onUpdateOrderIntentionsModal, onResetOrderIntentionsModal } =
  orderIntentionsModal.actions

export default orderIntentionsModal.reducer
