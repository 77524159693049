import {
  CONTACT_EDIT_RESET,
  CONTACT_EDIT,
  CONTACT_FILL,
  CONTACT_DELETE,
} from "../constants/actionTypes"

const initialState = {}

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_EDIT:
      return { ...state, [action.key]: action.value }
    case CONTACT_FILL:
      return { ...action.value }
    case CONTACT_EDIT_RESET:
      return initialState
    case CONTACT_DELETE:
      return { ...state, delete: action.value }
    default:
      return state
  }
}
