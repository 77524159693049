import { createSlice } from "@reduxjs/toolkit"
import { AccountsForUser, Signer } from "../interfaces/types"
import { EcheqModalAction, EcheqState } from "../components/echeqs/interfaces/types"

interface InitialState {
  open: boolean
  modalAction: EcheqModalAction | null
  data: {
    originAccount: AccountsForUser | null
    receiverAccount: {
      cuit: string
      companyName: string
    } | null
    amountToDebit: number | null
    state: EcheqState | null
    id: string | null
    date: Date | null
    requestingUserId: string | null
    signatureData?: {
      signers: Signer[]
      potentialSigners: Signer[]
      state: "ACTIVE" | "INACTIVE"
    } | null
  }
}

const initialState: InitialState = {
  open: false,
  modalAction: null,
  data: {
    originAccount: null,
    receiverAccount: null,
    amountToDebit: null,
    state: null,
    id: null,
    date: null,
    requestingUserId: null,
    signatureData: null,
  },
}

const echeqModal = createSlice({
  name: "Echeq Modal",
  initialState,
  reducers: {
    resetEcheqModal: () => {
      return initialState
    },
    updateEcheqModal: (state, action: { payload: Partial<InitialState> }) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { resetEcheqModal, updateEcheqModal } = echeqModal.actions

export default echeqModal.reducer
