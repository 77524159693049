import { createSlice } from "@reduxjs/toolkit"
import { SessionState, DeviceModel } from "../components/user/interfaces/types"
import { DataTimeObj } from "../interfaces/types"

interface InitialState {
  id: number | null
  state: SessionState | null
  device: {
    model: DeviceModel | null
    description: string
  }
  browser: string
  creationDateTime: DataTimeObj | null
  updateDateTime: DataTimeObj | null
  singInAt: DataTimeObj | null
  lastRefreshAt: DataTimeObj | null
  lastActivityAt: DataTimeObj | null
}

const initialState: InitialState = {
  id: null,
  state: null,
  device: {
    model: null,
    description: "",
  },
  browser: "",
  singInAt: null,
  lastRefreshAt: null,
  lastActivityAt: null,
  creationDateTime: null,
  updateDateTime: null,
}

const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    updateSession: (_, action) => {
      return action.payload
    },
    resetSession: () => {
      return initialState
    },
  },
})

export const { updateSession, resetSession } = session.actions

export default session.reducer
