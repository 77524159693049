import { createSlice } from "@reduxjs/toolkit"

interface InitialState {
  name: string
  accounts: string[]
  users?: string[]
}

const initialState: InitialState[] = []

const whitelist = createSlice({
  name: "whitelist",
  initialState,
  reducers: {
    updateWhiteList: (_, action) => {
      return [...action.payload]
    },
  },
})

export const { updateWhiteList } = whitelist.actions

export default whitelist.reducer
