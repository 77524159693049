import { createSlice } from "@reduxjs/toolkit"
import {
  InviteAction,
  InviteState,
  UserRole,
} from "../components/userManagement/interfaces/types"

export type InviteModalActions =
  | "NEW_INVITE"
  | "SELECT_ROLE"
  | "PRECONFIRMATION_DETAIL"
  | "AUTHORIZE_INVITE"
  | "POSTPONED_AUTHORIZATION"
  | "CONFIRM_EXIT"
  | "ROLES_INFO"
  | "CHANGE_ROLE"
  | "COMPARE_ROLES"
  | "INVITE_SENT"
  | "INVITE_REQUESTED"
  | "PENDING_LINK_APPROVAL"
  | "PENDING_LINK_ACCEPTING"
  | "EXPIRED_LINK_APPROVAL"
  | "EXPIRED_LINK_ACCEPTING"
  | "CONFIRM_REVOKE_INVITE"
  | "INVITE_ALREADY_REVOKED"
  | "INVITE_REVOKED"
  | "CONFIRM_EDIT_INVITE"
  | "ACTIVE_USER_DETAIL"
  | "INACTIVE_USER_DETAIL"
  | "PENDING_CHANGE_ROLE_ACCEPTING"
  | "PENDING_CHANGE_ROLE_APPROVAL"
  | "PENDING_ACTIVATION"
  | "PENDING_UNLINK"
  | "PENDING_SUSPENSION"
  | "CONFIRM_REVOKE_CHANGE_ROLE"
  | "CHANGE_ROLE_REVOKED"
  | "COMPARE_ROLES"
  | "AUTHORIZE_CHANGE_ROLE"
  | "ROLE_CHANGED"
  | "CONFIRM_SUSPEND_USER"
  | "AUTHORIZE_SUSPEND"
  | "USER_SUSPENDED"
  | "CONFIRM_REVOKE_SUSPEND"
  | "SUSPENTION_REVOKED"
  | "CONFIRM_REVOKE_ACTIVATION"
  | "ACTIVATION_REVOKED"
  | "AUTHORIZE_ACTIVATION"
  | "USER_ACTIVATED"
  | "AUTHORIZE_UNLINK"
  | "CONFIRM_REVOKE_UNLINK"
  | "USER_UNLINKED"
  | "UNLINK_REVOKED"
  | "CONFIRM_UNLINK_USER"
  | "UNLINK_ALREADY_ACCEPTED"
  | "UNLINK_REQUESTED"
  | "SUSPENTION_ALREADY_ACCEPTED"
  | "SUSPENTION_REQUESTED"
  | "CONFIRM_REACTIVATE_USER"
  | "ACTIVATION_REQUESTED"
  | "CONFIRM_CHANGE_ROLE"
  | "CHANGE_ROLE_REQUESTED"
  | "INVALID_EMAIL"
  | "NO_ACCESS"
  | "NO_ACCESS_ROLE"
  | null

interface InitialState {
  inviteData: {
    email: string | null
    action: InviteAction | null
    state: InviteState | null
    role: UserRole | null
    accountNameTojoin: string | null
    accountIdTojoin: number | null
    reportWrongData: boolean
    uuid: string
    creationDate: Date | null
    requestedBy: string
    inviteId: number | null
    expirationDate: Date | null
    approvalDate: Date | null
    acceptedDate: Date | null
    resendedDate: Date | null
    wrongData: "NO" | "YES_USER" | "YES_SYSTEM" | null
    requestingUserEmail: string | null
    loading: boolean
    resendRequested: boolean
  }
  guestData: {
    userExistence: boolean
    hasComplianceProfile: boolean
    userId: number | null
    email: string
    name: string
    lastName: string
    documentNumber: number | null
    documentType: string | null
    role: UserRole | null
    date: Date | null
    phoneNumber: string
    nationality: string
    taxIdentificationType: string
    taxIdentificationNumber: string
    address: string
    addressNumber: string
    department: string
    zipCode: string
    province: string
    country: string
    locality: string
    pep: string
  }
  newInviteModal: {
    isOpen: boolean
    action: InviteModalActions
    prevAction: InviteModalActions
    alert?: JSX.Element | string
  }
}

const initialState: InitialState = {
  inviteData: {
    email: null,
    action: null,
    state: null,
    role: null,
    accountNameTojoin: null,
    accountIdTojoin: null,
    reportWrongData: false,
    uuid: "",
    creationDate: null,
    requestedBy: "",
    inviteId: null,
    expirationDate: null,
    approvalDate: null,
    acceptedDate: null,
    resendedDate: null,
    wrongData: null,
    requestingUserEmail: null,
    loading: false,
    resendRequested: false,
  },
  guestData: {
    userExistence: false,
    hasComplianceProfile: false,
    userId: null,
    email: "",
    name: "",
    lastName: "",
    documentNumber: null,
    documentType: null,
    role: null,
    date: null,
    phoneNumber: "",
    nationality: "",
    taxIdentificationType: "",
    taxIdentificationNumber: "",
    address: "",
    addressNumber: "",
    department: "",
    zipCode: "",
    province: "",
    country: "",
    locality: "",
    pep: "",
  },
  newInviteModal: {
    isOpen: false,
    action: null,
    prevAction: null,
  },
}

const newInviteForm = createSlice({
  name: "New Invite Form",
  initialState,
  reducers: {
    resetNewInviteForm: () => {
      return initialState
    },
    updateNewInviteForm: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { resetNewInviteForm, updateNewInviteForm } = newInviteForm.actions

export default newInviteForm.reducer
