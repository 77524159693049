import {
  NEW_LINK_RESET,
  LINK_UPDATE_PROGRESS,
  UPDATE_NEW_LINK_FIELD,
} from "../constants/actionTypes"

const initialState = { progress: 25 }

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NEW_LINK_FIELD:
      return { ...state, [action.key]: action.value }
    case LINK_UPDATE_PROGRESS:
      return { ...state, progress: action.value }
    case NEW_LINK_RESET:
      return initialState
    default:
      return state
  }
}
