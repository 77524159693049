import { createSlice } from "@reduxjs/toolkit";
import { AllariaAccount, DarumaAccount } from "../interfaces/types";

interface InitialState {
    darumaAccount: DarumaAccount,
    allariaAccount: AllariaAccount,
    status: string,
    id: string,
    linkageStatus: string,
    linkId?: string,
    rejReason?: string,
    redemptionId?: string,
}

const initialState: InitialState = { 
  darumaAccount: { 
    accountId: "", 
    userId: "", 
    email: "", 
    name: "", 
    description: "" 
  }, 
  allariaAccount: { 
    accountNumber: 0, 
    cuit: "", 
    emails: [], 
    name: "",
    value: "",
    label: ""
  }, 
  status: "", 
  id: "", 
  linkageStatus: "" 
};


const newLinkageForm = createSlice({
  name: "New Linkage Form",
  initialState,
  reducers: {
    resetNewLinkage : () => {
      return initialState;
    },
    createNewLinkage: (state, action) => {
      return { ...state, ...action.payload };
    },
    allariaApproveNewLinkage: (state, action) => { 
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
  }
});

export const { resetNewLinkage, createNewLinkage, allariaApproveNewLinkage } = newLinkageForm.actions;

export default newLinkageForm.reducer;