import {
  isDesktop,
  isMobile,
  isTablet,
  browserName,
  deviceDetect,
  osName,
  mobileVendor,
  mobileModel,
} from "react-device-detect"
import { BrowserType } from "../interfaces/types"
import { DateType } from "../../../interfaces/types"
import moment from "moment"

const checkNone = (value: string) =>
  value.toLowerCase() === "none" || !value ? "" : value

export const getDeviceType = () => {
  if (isTablet) return "TABLET"
  if (isMobile) return "MOBILE"
  if (isDesktop) return "COMPUTER"
  return "UNKNOWN"
}

// eslint-disable-next-line unicorn/prefer-set-has
const browserTypes: BrowserType[] = [
  "Chrome",
  "Chromium",
  "Edge",
  "Edge Chromium",
  "Firefox",
  "IE",
  "Internet Explorer",
  "MIUI Browser",
  "Mobile Safari",
  "Opera",
  "Safari",
  "Samsung Browser",
  "Yandex",
]
export const getBrowserName = () => {
  //if browserName is not in browserTypes, return "Unknown"
  return browserTypes.includes(browserName as BrowserType)
    ? (browserName as BrowserType)
    : "Unknown"
}

export const getInfoDevice = () => deviceDetect(window.navigator.userAgent)

export const getDeviceName = () =>
  `${checkNone(mobileVendor)} ${checkNone(mobileModel)} ${checkNone(
    osName
  )}`.trim()

export const getDateTime = (time: DateType) => {
  const argentinaTime = new Date(time.seconds * 1000).toLocaleString("es-AR", {
    timeZone: "America/Argentina/Buenos_Aires",
  })
  const date = moment(argentinaTime, "DD-MM-YYYY, HH:mm:ss")
  return date
}
