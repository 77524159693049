import {
  MESSAGE_DELETE,
  MESSAGE_FORCE_DELETE,
  MESSAGE_SHOW,
} from "../constants/actionTypes"

const initialState = { close: true }

export default (state = initialState, action) => {
  switch (action.type) {
    case MESSAGE_SHOW:
      return {
        message: action.value,
        close: false,
        messageType: action.messageType ? action.messageType : "success",
        timeout: action.timeout ? action.timeout : 5000,
        onlyInPath: action.onlyInPath ? action.onlyInPath : false,
        onlyInOtherPath: action.onlyInOtherPath
          ? action.onlyInOtherPath
          : false,
        hash: Math.random().toString(36).slice(-8),
      }
    case MESSAGE_DELETE:
      return { ...state, close: true }
    case MESSAGE_FORCE_DELETE:
      return initialState
    default:
      return state
  }
}
