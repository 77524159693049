import {
  NEW_PROMISSORY_NOTE_RESET,
  UPDATE_NEW_PROMISSORY_NOTE_MASK_AMOUNT,
  UPDATE_NEW_PROMISSORY_NOTE_FIELD,
  RESET_BAILOR_AND_LINKS,
} from "../constants/actionTypes"

let initialState = { useSGR: true }

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NEW_PROMISSORY_NOTE_MASK_AMOUNT:
      return { ...state, maskAmount: action.maskAmount, amount: action.amount }
    case UPDATE_NEW_PROMISSORY_NOTE_FIELD:
      return { ...state, [action.key]: action.value }
    case RESET_BAILOR_AND_LINKS:
      return {
        ...state,
        bailorAccount: undefined,
        sgrOrDepositorSelected: undefined,
      }
    case NEW_PROMISSORY_NOTE_RESET:
      return { ...initialState }
    default:
      return state
  }
}
