import {
  CREATE_NEW_SERVICE_PAYMENT,
  RESET_NEW_SERVICE_PAYMENT,
  INVOICES_LIST,
} from "../constants/actionTypes"

const initialState = {
  accountId: "",
  cuit: "",
  provider: { id: "", name: "" },
  clientId: "",
  alias: "",
  newServicePaymentModal: {
    isOpen: false,
    action: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_NEW_SERVICE_PAYMENT:
      return initialState
    case CREATE_NEW_SERVICE_PAYMENT:
      return { ...state, ...action.newServicePayment }
    case INVOICES_LIST:
      return { ...state, invoices: action.value }
    default:
      return state
  }
}
