import { createSlice } from "@reduxjs/toolkit"
import {
  AddSuscriptorsModal,
  Client,
  ClientFormData,
  DebManagementModal,
  Personalization,
  Plan,
  PlanFormData,
  SubscriberPaymentMethods,
} from "../components/charges/interfaces/types"

interface ClientForm {
  openModal: boolean
  type: "CREATE" | "UPDATE" | "IMPORT_CLIENTS"
  data: ClientFormData
}

interface PlanForm {
  openModal: boolean
  state: "CREATE" | "UPDATE" | "SHARE" | "TOGGLE_STATE" | "SUCCESS"
  planData: Plan | null
  formData: PlanFormData
}

interface SubscribeForm {
  openModal: boolean
  state: "FORM" | "SUCCESS"
  clients: Client[]
  plans: Plan[]
}

interface DeleteClientForm {
  openModal: boolean
  clients: Client[]
  state: "CHOOSE" | "NOW" | "AUTHORIZE" | "SUCCESS" | null
  fromState: "CHOOSE" | "NOW" | "AUTHORIZE" | "SUCCESS" | null
}

interface CustomizeChargesModal {
  openModal: boolean
  action: "DETAIL" | "TOKEN" | "SUCCESS"
}

interface UnsubscribeClientForm {
  openModal: boolean
  state: "UNSUBSCRIBE" | "AUTHORIZE" | "SUCCESS"
  client: Client | null
  plan: Plan | null
}

export type ClientViewFormState =
  | "PAYMENTS_DETAIL"
  | "ADD_PAYMENT_METHOD"
  | "FIRST_PAYMENT"
export type ClientViewFormModalState =
  | "CANCEL_SUBSCRIPTION"
  | "DELETE_PAYMENT_METHOD"
  | "SUCCESS"
  | "SUCCESS_PAYMENT"

interface ClientViewForm {
  openModal: boolean
  prevModalState: ClientViewFormModalState | null
  state: ClientViewFormState | null
  modalState: ClientViewFormModalState | null
  subscriptionId: string | null
  subscriberId: string | null
  subscriberPaymentMethods: SubscriberPaymentMethods | null
  updatePaymentMethods: boolean
  validPaymentMethodForm: boolean
  deletePaymentMethodId: string | null
  paidAt: Date | null
  paymentId: string | null
  paidAmount: number
  newPaymentMethodForm: {
    pan: string
    expirationDate: string
    pin: string
    cardholderName: string
    amount: number
    isArgentineResident: boolean
    documentNumber: string | null
    province: string | null
  }
}

interface InitialState {
  plansData: Plan[]
  clientForm: ClientForm
  planForm: PlanForm
  subscribeForm: SubscribeForm
  debtManagementModal: DebManagementModal
  deleteClientForm: DeleteClientForm
  customizeChargesModal: CustomizeChargesModal
  addSuscriptorsModal: AddSuscriptorsModal
  unsubscribeClientForm: UnsubscribeClientForm
  clientViewForm: ClientViewForm
}

const initialState: InitialState = {
  plansData: [],
  clientForm: {
    openModal: false,
    type: "CREATE",
    data: {
      email: "",
      name: "",
      phoneNumber: "",
      referenceCode: "",
      taxIdentificationNumber: "",
      taxIdentificationType: "",
    },
  },
  planForm: {
    openModal: false,
    state: "CREATE",
    planData: null,
    formData: {
      name: "",
      description: "",
      amount: "",
      frequency: "",
      duration: "",
      paymentsQuantity: "",
      proportionalPaymentChoice: false,
      payWeek: "",
      creditCard: false,
      debitCard: false,
      prepaidCard: false,
      referenceCode: "",
      redirectUrl: "",
      logoImageUrl: "",
      productImageUrl: "",
    },
  },
  subscribeForm: {
    state: "FORM",
    openModal: false,
    clients: [],
    plans: [],
  },
  debtManagementModal: {
    isOpen: false,
    action: null,
    linkId: null,
    payments: [],
    plan: null,
    description: null,
    clients: [],
  },
  deleteClientForm: {
    openModal: false,
    clients: [],
    state: null,
    fromState: null,
  },
  customizeChargesModal: {
    openModal: false,
    action: "DETAIL",
  },
  addSuscriptorsModal: {
    openModal: false,
    state: null,
    type: null,
    clients: [],
    planData: null,
  },
  unsubscribeClientForm: {
    state: "UNSUBSCRIBE",
    openModal: false,
    client: null,
    plan: null,
  },
  clientViewForm: {
    openModal: false,
    prevModalState: null,
    state: null,
    modalState: null,
    subscriptionId: null,
    subscriberId: null,
    subscriberPaymentMethods: null,
    updatePaymentMethods: false,
    validPaymentMethodForm: false,
    deletePaymentMethodId: null,
    paidAt: null,
    paymentId: null,
    paidAmount: 0,
    newPaymentMethodForm: {
      pan: "",
      expirationDate: "",
      pin: "",
      cardholderName: "",
      isArgentineResident: false,
      documentNumber: null,
      province: null,
      amount: 0,
    },
  },
}

const charges = createSlice({
  name: "Charges",
  initialState,
  reducers: {
    //CLIENT FORM
    onUpdateClientForm: (state, action: { payload: Partial<ClientForm> }) => {
      return {
        ...state,
        clientForm: { ...state.clientForm, ...action.payload },
      }
    },
    onResetClientForm: (state) => {
      return { ...state, clientForm: initialState.clientForm }
    },
    //PLAN FORM
    onUpdatePlanForm: (state, action: { payload: Partial<PlanForm> }) => {
      return { ...state, planForm: { ...state.planForm, ...action.payload } }
    },
    onResetPlanForm: (state) => {
      return { ...state, planForm: initialState.planForm }
    },
    //SUBSCRIBER FORM
    onUpdateSubscribeForm: (
      state: InitialState,
      action: { payload: Partial<SubscribeForm> }
    ) => {
      return {
        ...state,
        subscribeForm: { ...state.subscribeForm, ...action.payload },
      }
    },
    onResetSubscribeForm: (state) => {
      return { ...state, subscribeForm: initialState.subscribeForm }
    },
    onAddClientSubscribeForm: (state, action) => {
      return {
        ...state,
        subscribeForm: {
          ...state.subscribeForm,
          clients: [state.subscribeForm.clients, action.payload],
        },
      }
    },
    onRemoveClientSubscribeForm: (state, action) => {
      return {
        ...state,
        subscribeForm: {
          ...state.subscribeForm,
          clients: state.subscribeForm.clients.filter(
            (client) => client !== action.payload
          ),
        },
      }
    },
    //DEBT MANAGEMENT MODAL
    onUpdateDebtManagementModal: (
      state: InitialState,
      action: { payload: Partial<DebManagementModal> }
    ) => {
      return {
        ...state,
        debtManagementModal: {
          ...state.debtManagementModal,
          ...action.payload,
        },
      }
    },
    onResetDebtManagementModal: (state: InitialState) => {
      return { ...state, debtManagementModal: initialState.debtManagementModal }
    },
    //DELETE CLIENT FORM
    onUpdateDeleteClientForm: (
      state,
      action: { payload: Partial<DeleteClientForm> }
    ) => {
      return {
        ...state,
        deleteClientForm: { ...state.deleteClientForm, ...action.payload },
      }
    },
    onResetDeleteClientForm: (state) => {
      return { ...state, deleteClientForm: initialState.deleteClientForm }
    },
    //CUSTOMIZE CHARGES MODAL
    onUpdateCustomizeChargesModal: (
      state,
      action: { payload: Partial<CustomizeChargesModal> }
    ) => {
      return {
        ...state,
        customizeChargesModal: {
          ...state.customizeChargesModal,
          ...action.payload,
        },
      }
    },
    onUpdateAddSuscriptorsModal: (
      state: InitialState,
      action: { payload: Partial<AddSuscriptorsModal> }
    ) => {
      return {
        ...state,
        addSuscriptorsModal: {
          ...state.addSuscriptorsModal,
          ...action.payload,
        },
      }
    },
    onResetAddSuscriptorsModal: (state: InitialState) => {
      return { ...state, addSuscriptorsModal: initialState.addSuscriptorsModal }
    },
    //UNSUBSCRIBE CLIENT FORM
    onUpdateUnsubscribeClientForm: (
      state,
      action: { payload: Partial<UnsubscribeClientForm> }
    ) => {
      return {
        ...state,
        unsubscribeClientForm: {
          ...state.unsubscribeClientForm,
          ...action.payload,
        },
      }
    },
    onResetUnsubscribeClientForm: (state) => {
      return {
        ...state,
        unsubscribeClientForm: initialState.unsubscribeClientForm,
      }
    },
    onUpdatePlansData: (state, action) => {
      return {
        ...state,
        plansData: action.payload,
      }
    },
    onUpdateClientViewForm: (
      state,
      action: { payload: Partial<ClientViewForm> }
    ) => {
      return {
        ...state,
        clientViewForm: { ...state.clientViewForm, ...action.payload },
      }
    },
    onResetClientViewForm: (state) => {
      return { ...state, clientViewForm: initialState.clientViewForm }
    },
  },
})

export const {
  onUpdateClientForm,
  onResetClientForm,
  onUpdatePlanForm,
  onResetPlanForm,
  onUpdateSubscribeForm,
  onResetSubscribeForm,
  onAddClientSubscribeForm,
  onRemoveClientSubscribeForm,
  onUpdateDebtManagementModal,
  onResetDebtManagementModal,
  onUpdateDeleteClientForm,
  onResetDeleteClientForm,
  onUpdateCustomizeChargesModal,
  onUpdateAddSuscriptorsModal,
  onResetAddSuscriptorsModal,
  onUpdateUnsubscribeClientForm,
  onResetUnsubscribeClientForm,
  onUpdatePlansData,
  onUpdateClientViewForm,
  onResetClientViewForm,
} = charges.actions

export default charges.reducer
