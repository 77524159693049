/* eslint-disable unicorn/prefer-spread */
import {
  CANCEL_TRANSACTION,
  NEW_TRANSACTION_RESET,
  TRANSACTION_UPDATE_PROGRESS,
  UPDATE_NEW_TRANSACTION_FIELD,
  UPDATE_NEW_TRANSACTION,
  UPDATE_NEW_TRANSACTION_VALIDATION,
  UPDATE_MASK_TAXES,
  UPDATE_MASK_AMOUNT,
  ADD_EMAIL_NOTIFICATION,
  DELETE_EMAIL_NOTIFICATION,
  SOFT_RESET_TRANSACTION,
  CREATE_NEW_TRANSACTION,
} from "../constants/actionTypes"

const initialState = {
  progress: 16,
  maskAmount: "",
  amount: "",
  taxes: "",
  receiver: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MASK_AMOUNT:
      return { ...state, maskAmount: action.maskAmount, amount: action.amount }
    case UPDATE_MASK_TAXES:
      return { ...state, taxes: action.taxes }
    case UPDATE_NEW_TRANSACTION_FIELD:
      return { ...state, [action.key]: action.value }
    case UPDATE_NEW_TRANSACTION:
      return { ...state, ...action.newTransaction }
    case TRANSACTION_UPDATE_PROGRESS:
      return { ...state, progress: action.value }
    case CANCEL_TRANSACTION:
      return { ...state, cancel: action.value }
    case NEW_TRANSACTION_RESET:
      return initialState
    case UPDATE_NEW_TRANSACTION_VALIDATION:
      return { ...state, validations: { [action.key]: action.value } }
    case ADD_EMAIL_NOTIFICATION:
      return {
        ...state,
        notificationEmail: state.notificationEmail
          ? state.notificationEmail.concat(action.value)
          : [action.value],
      }
    case DELETE_EMAIL_NOTIFICATION:
      return {
        ...state,
        notificationEmail: state.notificationEmail.filter(
          (ele) => ele !== action.value
        ),
      }
    case SOFT_RESET_TRANSACTION:
      return {
        ...initialState,
        accountId: state.accountId,
        progress: state.progress,
      }
    case CREATE_NEW_TRANSACTION:
      return { ...state, ...action.newTransaction }
    default:
      return state
  }
}
