import {
  UPDATE_NEW_TRANSFER_REQUEST_FIELD,
  UPDATE_NEW_TRANSFER_REQUEST,
  NEW_TRANSFER_REQUEST_RESET,
} from "../constants/actionTypes"

const initialState = {
  originAccount: {
    allariaId: "",
    linkId: "",
    cuit: "",
  },
  destinationAccount: {
    name: "",
    cvu: "",
  },
  amount: "",
  maskAmount: "",
  taxes: "",
  requestStatus: "",
  id: "",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NEW_TRANSFER_REQUEST_FIELD:
      return { ...state, [action.key]: action.value }
    case UPDATE_NEW_TRANSFER_REQUEST:
      return { ...state, ...action.newTransferRequest }
    case NEW_TRANSFER_REQUEST_RESET:
      return initialState
    default:
      return state
  }
}
