import {
  NEW_ECHEQ_RESET,
  UPDATE_NEW_ECHEQ_FIELD,
} from "../constants/actionTypes"

let initialState = { useSGR: true }

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NEW_ECHEQ_FIELD:
      return { ...state, [action.key]: action.value }
    case NEW_ECHEQ_RESET:
      return { ...initialState }
    default:
      return state
  }
}
