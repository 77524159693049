import {
  UPDATE_NEW_MASSIVE_TRANSACTION_FIELD,
  UPDATE_NEW_MASSIVE_TRANSACTION,
  NEW_MASSIVE_TRANSACTION_RESET,
} from "../constants/actionTypes"

const initialState = {
  originAccount: {
    id: "",
    isAdmin: false,
  },
  massiveTransactionId: "",
  transactionsIds: [],
  status: "",
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_NEW_MASSIVE_TRANSACTION_FIELD:
      return { ...state, [action.key]: action.value }
    case UPDATE_NEW_MASSIVE_TRANSACTION:
      return { ...state, ...action.massiveTransaction }
    case NEW_MASSIVE_TRANSACTION_RESET:
      return initialState
    default:
      return state
  }
}
