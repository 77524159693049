import { createSlice } from "@reduxjs/toolkit"

interface InitialState {
  values: string[]
}

const initialState: InitialState = {
  values: [],
}

const holidaysReducer = createSlice({
  name: "Holidays",
  initialState,
  reducers: {
    changeHolidays: (state, action) => {
      state.values = action.payload
    },
  },
})

export const { changeHolidays } = holidaysReducer.actions

export default holidaysReducer.reducer
