import {
  NEW_CONTACT_RESET,
  CREATE_NEW_CONTACT,
  UPDATE_NEW_CONTACT_FIELD,
  UPDATE_NEW_CONTACT_VALIDATION,
} from "../constants/actionTypes"

export default (state = {}, action) => {
  switch (action.type) {
    case UPDATE_NEW_CONTACT_FIELD:
      return { ...state, [action.key]: action.value }
    case CREATE_NEW_CONTACT:
      return { ...state, ...action.newContact }
    case UPDATE_NEW_CONTACT_VALIDATION:
      return { ...state, validations: { [action.key]: action.value } }
    case NEW_CONTACT_RESET:
      return {}
    default:
      return state
  }
}
