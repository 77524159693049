import {
  SETTINGS_EDIT,
  UPDATE_ACCOUNTS,
  UPDATE_ACCOUNTS_FOR_USER,
} from "../constants/actionTypes"

const initialState = {
  accounts: null,
  accountsForUser: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_EDIT:
      return { ...state, [action.key]: action.value }
    case UPDATE_ACCOUNTS:
      return { ...state, accounts: action.value }
    case UPDATE_ACCOUNTS_FOR_USER:
      return { ...state, accountsForUser: action.value }
    default:
      return state
  }
}
