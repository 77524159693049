import styles from "./Button.module.scss"

const LoadingCircles = ({
  greyVariant = false,
  fullScreen,
  className,
}: {
  greyVariant?: boolean
  fullScreen?: boolean
  className?: string
}) => {
  return (
    <div id={styles["circleG"]} className={className}>
      <div
        id={styles["circleG_1"]}
        className={`${styles.circleG} ${greyVariant && styles.greyVariant} ${
          fullScreen && styles.fullScreen
        }`}
      ></div>
      <div
        id={styles["circleG_2"]}
        className={`${styles.circleG} ${greyVariant && styles.greyVariant} ${
          fullScreen && styles.fullScreen
        }`}
      ></div>
      <div
        id={styles["circleG_3"]}
        className={`${styles.circleG} ${greyVariant && styles.greyVariant} ${
          fullScreen && styles.fullScreen
        }`}
      ></div>
    </div>
  )
}

export default LoadingCircles
