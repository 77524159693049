/* eslint-disable @typescript-eslint/no-var-requires, unicorn/prefer-module */
import { AuthStatus } from "../authentication/utils/AuthStatus"
import { connect } from "react-redux"
import {
  AUTHENTICATE,
  LOGOUT,
  UPDATE_CHANGE_PASSWORD_FIELD,
} from "../../constants/actionTypes"
import firebase from "firebase/app"
import { Redirect, useLocation } from "react-router-dom"
import { checkUserState } from "../utils/authentication"
import queryString from "query-string"
import { updatePasswordDate } from "../authentication/connectors/authenticationConnectors"

const unLoggedPages = [
  "/aprobar-vinculacion-usuario",
  "/allaria-autorizar-vinculacion",
  "/allaria-autorizar-vinculacion/rechazo",
  "/allaria-autorizar-vinculacion/final",
  "/allaria-autorizar-vinculacion/token",
  "/usuario-autorizar-vinculacion",
  "/usuario-autorizar-vinculacion/token",
  "/usuario-autorizar-vinculacion/final",
]

function onFirebaseChange(user) {
  return async (dispatch, getState) => {
    if (user) {
      if (
        getState().authentication.authenticated === AuthStatus.UNKNOWN ||
        getState().authentication.email !== user.email
      ) {
        const userFirestore = await firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get()

        const userData = userFirestore.data()

        if (!userData?.passwordUpdatedAt) {
          await updatePasswordDate({ uid: user.uid })
        }

        const userState = await checkUserState(user?.uid)

        if (userState !== "ACTIVE") {
          dispatch({
            type: UPDATE_CHANGE_PASSWORD_FIELD,
            key: "authenticated",
            value: AuthStatus.UNAUTHORIZED,
          })
          return
        }

        dispatch({
          type: AUTHENTICATE,
          data: { email: user.email, displayName: user.displayName },
        })
      }
    } else {
      if (
        getState().authentication.authenticated === AuthStatus.AUTHENTICATED
      ) {
        window.location.reload()
      }
      dispatch({ type: LOGOUT })
    }
  }
}

const LoadingPageBlank = (props) => {
  const location = useLocation()
  const parsedUrl = queryString.parse(location.search)

  firebase.auth().onAuthStateChanged((user) => {
    props.dispatch(onFirebaseChange(user))
  })

  const CallbackRedirect = () => {
    if (props.authenticated === AuthStatus.AUTHENTICATED) {
      return parsedUrl.callback ? (
        <Redirect to={parsedUrl.callback} />
      ) : (
        <Redirect to="/" />
      )
    }

    if (props.authenticated === AuthStatus.UNAUTHORIZED) {
      if (parsedUrl.callback) {
        return unLoggedPages.some((element) =>
          parsedUrl.callback.includes(element)
        ) ? (
          <Redirect to={parsedUrl.callback} />
        ) : (
          <Redirect
            to={`/login?callback=${encodeURIComponent(parsedUrl.callback)}`}
          />
        )
      } else {
        return <Redirect to="/login" />
      }
    }

    return null
  }

  return (
    <div>
      <CallbackRedirect />
    </div>
  )
}
const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
})

const mapDispatchToProps = (dispatch) => ({
  login: (user) =>
    dispatch({
      type: AUTHENTICATE,
      data: { email: user.email, displayName: user.displayName },
    }),
  logout: () => dispatch({ type: LOGOUT }),
  dispatch: (data) => dispatch(data),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoadingPageBlank)
