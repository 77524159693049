import { Suspense, useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { store } from "./store"
import { Route, Router, Switch, useLocation } from "react-router-dom"
import { createBrowserHistory } from "history"
import { ReactReduxFirebaseProvider } from "react-redux-firebase"
import { createFirestoreInstance } from "redux-firestore"
import firebase from "firebase/app"
import ScrollToTop from "./components/utils/ScrollToTop"
import * as Sentry from "@sentry/react"
import "regenerator-runtime/runtime"
import RenderLoader from "./components/renderLoader/RenderLoader"
import { ErrorBoundary } from "react-error-boundary"
import ErrorFallback from "./components/utils/ErrorFallback"
import { indexRoutesList } from "./routes/indexRoutes"
import { EMAIL_SUPPORT, PHONE_NUMBER_FOR_TEXT } from "./helpers/allariaSupport"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"

//SCSS global file
import "./assets/scss/global.scss"

declare global {
  interface Window {
    Cypress: any
    store: typeof store
  }
}

console.log("%c¡Cuidado!", "color: red; font-size: 40px; font-weight: bold;")
console.log(
  "%cEsta función del navegador está pensada para desarrolladores. Si alguien te indicó que pusieras algo acá, se trata de un fraude. Si lo hacés, esta persona podrá acceder a tu cuenta y realizar transacciones a tu nombre.",
  "font-size: 20px;"
)
console.log(
  `%cSi alguien te pidió que hicieras esto, por favor, reportalo al equipo de Allaria+ en ${EMAIL_SUPPORT} o escribinos por Whatsapp al ${PHONE_NUMBER_FOR_TEXT}.`,
  "font-size: 15px;"
)
console.log(
  "%cSi entraste por accidente, cerrá esta ventana.",
  "font-size: 14px;"
)

const rrfConfig = {
  //userProfile: 'users', // where profiles are stored in database
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
}

const history = createBrowserHistory()

const SentryRoute = Sentry.withSentryRouting(Route)

const isLocalhost = window.location.hostname === "localhost"

//Sentry must be initialized before react
Sentry.init({
  dsn: "https://033fa834cc064f63bd4d2b173b8cf0c5@o303248.ingest.sentry.io/1731892",
  environment: import.meta.env.REACT_APP_environment,
  release: import.meta.env.REACT_APP_VERSION,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({
      history,
    }),
    Sentry.replayIntegration(),
  ],
  normalizeDepth: 10,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1,
  // routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
  beforeBreadcrumb(breadcrumb) {
    return breadcrumb.category === "console" && breadcrumb.level === "warning"
      ? null
      : breadcrumb
  },
  beforeSend(event, hint) {
    const ignoredErrors = ["Failed to fetch dynamically imported module"]

    const originalException = hint?.originalException

    if (originalException instanceof Error) {
      const errorMessage = originalException?.message

      if (
        errorMessage &&
        ignoredErrors.some((error) => errorMessage?.includes(error))
      ) {
        // Return null to prevent the error from being sent to Sentry
        return null
      }
    }

    //Otherwise, send the error to Sentry
    return event
  },
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

export { history, SentryRoute }

// Create a react-query client and default props
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 3,
      refetchOnWindowFocus: true,
      refetchOnReconnect: true,
      refetchOnMount: true,
      staleTime: 0,
    },
  },
})

// This component prevents updating app when the route is login or loading-blank
// This is to prevent updating when user is entering login information
const AutoUpdateApp = ({ children }: { children: JSX.Element }) => {
  // To register the SW only once
  const [registeredSW, setRegisteredSW] = useState(false)

  // To prevent registering SW on first pathname load that is always "/"
  const [currentPathname, setCurrentPathname] = useState<string>()

  // Get location
  const location = useLocation()

  // Don't update on this routes
  const noUpdateRoutes = ["login", "loading-blank"]

  const registerPWA = async () => {
    const { registerSW } = await import("virtual:pwa-register")
    registerSW({ immediate: true })
    setRegisteredSW(true)
  }

  useEffect(() => {
    if (
      !noUpdateRoutes.some((route) => location.pathname.includes(route)) &&
      currentPathname &&
      !registeredSW
    ) {
      // Automatic page reload when new version is available
      registerPWA()
    }
    setCurrentPathname(location.pathname)
  }, [location])

  return children
}

const MainPage = () => {
  const fallback = (
    <span>
      Hubo un error en la página. Contáctese con {EMAIL_SUPPORT} o escribinos
      por Whatsapp al {PHONE_NUMBER_FOR_TEXT}.
    </span>
  )

  useEffect(() => {
    if (window.location.origin.includes("midaruma")) {
      let domain = "dev"
      if (import.meta.env.REACT_APP_environment !== "dev") {
        domain = "com.ar"
      }
      window.location.replace(
        `https://app.allariamas.${domain}${location.pathname}${window.location.search}`
      )
    }
  }, [window.location])

  return (
    <Sentry.ErrorBoundary fallback={fallback}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <ReactReduxFirebaseProvider {...rrfProps}>
              <Router history={history}>
                <AutoUpdateApp>
                  <Suspense fallback={<RenderLoader />}>
                    <ScrollToTop>
                      <Switch>
                        {indexRoutesList.map(({ name, ...rest }) => (
                          <SentryRoute key={name} {...rest} />
                        ))}
                      </Switch>
                    </ScrollToTop>
                  </Suspense>
                </AutoUpdateApp>
              </Router>
            </ReactReduxFirebaseProvider>
          </Provider>
          {isLocalhost && <ReactQueryDevtools />}
        </QueryClientProvider>
      </ErrorBoundary>
    </Sentry.ErrorBoundary>
  )
}

ReactDOM.render(<MainPage />, document.getElementById("root"))
