import { createSlice } from "@reduxjs/toolkit"

interface InitialState {
  modalPadding: number
  headerHeight: number
  buttonsHeight: number
}

const initialState: InitialState = {
  modalPadding: 0,
  headerHeight: 0,
  buttonsHeight: 0,
}

const modalSlice = createSlice({
  name: "modalBodyDimensions",
  initialState,
  reducers: {
    updateDimensions: (state, action) => {
      return { ...state, ...action.payload }
    },
    resetDimensions: () => {
      return initialState
    },
  },
})

export const { updateDimensions, resetDimensions } = modalSlice.actions

export default modalSlice.reducer
