import {
  CANCEL_SOLICITUDE,
  NEW_SOLICITUDE_RESET,
  SOLICITUDE_UPDATE_PROGRESS,
  UPDATE_NEW_SOLICITUDE_FIELD,
  UPDATE_NEW_SOLICITUDE_VALIDATION,
  UPDATE_SOLICITUDE_MASK_AMOUNT,
  SOFT_RESET_SOLICITUDE,
} from "../constants/actionTypes"

const initialState = {
  progress: 16,
  maskAmount: "",
  amount: "",
  withRetrieve: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SOLICITUDE_MASK_AMOUNT:
      return { ...state, maskAmount: action.maskAmount, amount: action.amount }
    case UPDATE_NEW_SOLICITUDE_FIELD:
      return { ...state, [action.key]: action.value }
    case SOLICITUDE_UPDATE_PROGRESS:
      return { ...state, progress: action.value }
    case CANCEL_SOLICITUDE:
      return { ...state, cancel: action.value }
    case NEW_SOLICITUDE_RESET:
      return initialState
    case UPDATE_NEW_SOLICITUDE_VALIDATION:
      return { ...state, validations: { [action.key]: action.value } }
    case SOFT_RESET_SOLICITUDE:
      return {
        ...initialState,
        accountId: state.accountId,
        progress: state.progress,
      }
    default:
      return state
  }
}
