import { CONTACTS_CHANGE_QUERY } from "../constants/actionTypes"

export default (state = { query: "" }, action) => {
  switch (action.type) {
    case CONTACTS_CHANGE_QUERY:
      return { query: action.value }
    default:
      return state
  }
}
