import {
  RESET_TRANSFER_REQUEST_SELECTED,
  SET_TRANSFER_REQUEST_SELECTED,
} from "../constants/actionTypes"

const initialState = {
  darumaAccount: { cvu: "", id: "", name: "" },
  allariaAccount: "",
  state: "",
  reason: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_TRANSFER_REQUEST_SELECTED:
      return initialState
    case SET_TRANSFER_REQUEST_SELECTED:
      return { ...state, ...action.value }
    default:
      return state
  }
}
