import {
  getBrowserName,
  getDeviceName,
  getDeviceType,
} from "../components/user/devicesSettings/utils"
import { Device } from "../interfaces/types"
import { createSlice } from "@reduxjs/toolkit"

const initialState: Device = {
  deviceUid: "",
  deviceModel: getDeviceType(),
  deviceDescription: getDeviceName(),
  browserType: getBrowserName(),
}

const device = createSlice({
  name: "device",
  initialState,
  reducers: {
    updateDevice: (state, action) => {
      return { ...state, ...action.payload }
    },
    updateDeviceUid: (state, action) => {
      const deviceUid = action.payload

      return { ...state, deviceUid }
    },
  },
})

export const { updateDevice, updateDeviceUid } = device.actions

export default device.reducer
