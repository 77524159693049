import { createSlice } from "@reduxjs/toolkit"
import { AccountsForUser } from "../interfaces/types";

const initialState: AccountsForUser[] = []

const accountsForUser = createSlice({
  name: "accountsForUser",
  initialState,
  reducers: {
    updateAccountsForUser: (_, action) => {
      return [...action.payload]
  },
  },
})

export const { updateAccountsForUser } = accountsForUser.actions

export default accountsForUser.reducer
