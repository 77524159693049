import { useLocation } from "react-router-dom"
import { useEffect } from "react"
import useQuery from "../../hooks/useQuery"

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation()
  const faqQuestion = useQuery().get("question")

  useEffect(() => {
    if (pathname === "/preguntas-frecuentes/?section=transferencias" && faqQuestion)
      return
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
}

export default ScrollToTop
