import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  updated: false,
  updating: false,
}

const updateServiceWorker = createSlice({
  name: "Service Worker",
  initialState,
  reducers: {
    onWorkerAvailable: (state) => {
      state.updated = true
    },
    onUpdate: (state) => {
      state.updating = true
    },
  },
})

export const { onWorkerAvailable, onUpdate } = updateServiceWorker.actions

export default updateServiceWorker.reducer
