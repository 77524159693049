import { createSlice } from "@reduxjs/toolkit"

const initialState = false

const session = createSlice({
  name: "session",
  initialState,
  reducers: {
    loggedFromAllaria: () => {
      sessionStorage.setItem("isAllariaLogin", "true")
      return true
    },
    resetSession: () => {
      sessionStorage.removeItem("isAllariaLogin")
      return initialState
    },
  },
})

export const { loggedFromAllaria, resetSession } = session.actions

export default session.reducer
