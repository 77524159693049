import { createSlice } from "@reduxjs/toolkit"
import { DataTime } from "../interfaces/types"
import firebase from "firebase/app"

interface ShowModal {
  showModal: boolean
}

interface NewSectionTag {
  firstSightDate: firebase.firestore.Timestamp
  pageReloads: number
  hasEntered: boolean
  showCouchMark: boolean
  skipCouchMark: boolean
  showPopup: boolean
}

interface InitialState {
  allariaEcosystem?: ShowModal & NewSectionTag
  vepPayments?: ShowModal & NewSectionTag
  paymentLinks?: ShowModal & NewSectionTag
  investments?: ShowModal & NewSectionTag
  servicePayments?: ShowModal & NewSectionTag
  sessionsConfigPopup?: ShowModal & NewSectionTag
  whatsapp2faPopup?: ShowModal & NewSectionTag
  [key: string]: (NewSectionTag & ShowModal) | undefined
}

const initialState: InitialState = {}

const newSectionTags = createSlice({
  name: "New Section Tags",
  initialState,
  reducers: {
    onUpdateNewSectionTag: (state, action) => {
      return { ...state, ...action.payload }
    },
    onResetNewSectionTag: () => {
      return initialState
    },
  },
})

export const { onUpdateNewSectionTag, onResetNewSectionTag } =
  newSectionTags.actions

export default newSectionTags.reducer
