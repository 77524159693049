import { createSlice } from "@reduxjs/toolkit"
import { DataTime } from "../interfaces/types"

interface InitialState {
  name: string
  tna: number
  anualTnaPhysical: number
  anualTnaJuridical: number
  composition: { description: string; percentage: number }[]
  tnaLabel: string
  updatedAt: DataTime
}

const initialState: InitialState[] = []

const fundsData = createSlice({
  name: "fundsData",
  initialState,
  reducers: {
    updateFundsData: (_, action) => {
      return [...action.payload]
    },
  },
})

export const { updateFundsData } = fundsData.actions

export default fundsData.reducer
