import { createSlice } from "@reduxjs/toolkit"

interface InitialState {
  accountId: any
  amount: any
  description: any
  maskAmount: any
  newTransactionModal: {
    isOpen: boolean
    action:
      | "CANCEL"
      | "AUTHORIZE"
      | "CONFIRM"
      | "SUCCESSFUL_AUTHORIZATION"
      | "PRE_CANCEL"
      | "SUCCESS_CANCEL"
      | "POSTPONE-AUTHORIZATION"
      | "PENDING-AUTHORIZATION"
      | null
  }
  notificationEmail: any
  receiver: any
  scheduledDate: any
  progress: number
  errorTaxes: boolean
  editTransaction: boolean
}

const initialState: InitialState = {
  accountId: "",
  amount: "",
  description: "",
  maskAmount: "",
  newTransactionModal: {
    isOpen: false,
    action: null,
  },
  notificationEmail: "",
  receiver: {},
  scheduledDate: "",
  progress: 16,
  errorTaxes: false,
  editTransaction: false,
}

const newTransactionForm = createSlice({
  name: "New Transaction Form",
  initialState,
  reducers: {
    onUpdateNewTransaction: (state, action) => {
      return { ...state, ...action.payload }
    },
    onResetNewTransactionState: () => {
      return initialState
    },
  },
})

export const { onUpdateNewTransaction, onResetNewTransactionState } =
  newTransactionForm.actions

export default newTransactionForm.reducer
