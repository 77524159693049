import { createSlice } from "@reduxjs/toolkit"

interface InitialState {
  selectedAccount: string
}

const initialState: InitialState = {
  selectedAccount: "ALL",
}

const accountSelectionReducer = createSlice({
  name: "Account Selection",
  initialState,
  reducers: {
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload
    },
    resetSelectedAccount: () => initialState,
  },
})

export const { setSelectedAccount, resetSelectedAccount } =
  accountSelectionReducer.actions

export default accountSelectionReducer.reducer
