import { createSlice } from "@reduxjs/toolkit"
import {
  Actions,
  PreconfirmationData,
  Types,
  AllariaAccountRequest,
} from "../components/allariaEcosystem/ui/FundsManagementModal/interfaces/types"
import {
  FundsTransfer,
  TransferRequest,
} from "../components/movements/interfaces/types"
import { AllariaTransfer } from "../components/allariaEcosystem/interfaces/types"

interface InitialState {
  action: Actions
  type: Types
  data: FundsTransfer | TransferRequest | AllariaTransfer | null
  preconfirmationData: PreconfirmationData | null
  open: boolean
  allariaAccountBalanceRequestData: AllariaAccountRequest | null
  refetchTableData: boolean
}

const initialState: InitialState = {
  action: null,
  type: null,
  data: null,
  preconfirmationData: null,
  open: false,
  allariaAccountBalanceRequestData: null,
  refetchTableData: false,
}

const fundsManagementModal = createSlice({
  name: "fundsManagementModal",
  initialState,
  reducers: {
    onUpdateModal: (state, action) => {
      return { ...state, ...action.payload }
    },
    onResetModal: () => {
      return { ...initialState }
    },
    onUpdateAllariaAccountBalanceRequestData: (state, action) => {
      return {
        ...state,
        allariaAccountBalanceRequestData: {
          ...state.allariaAccountBalanceRequestData,
          ...action.payload,
        },
      }
    },
  },
})

export const {
  onUpdateModal,
  onResetModal,
  onUpdateAllariaAccountBalanceRequestData,
} = fundsManagementModal.actions

export default fundsManagementModal.reducer
