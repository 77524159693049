import { createSlice } from "@reduxjs/toolkit"
import { AllariaCxu, AllariaCxuFormData } from "../components/allariaEcosystem/interfaces/types"

interface InitialState {
  open: boolean
  action: "FORM" | "PRECONFIRMATION" | "DETAIL" | "CONFIRM_DELETE" | "SUCCESS_DELETE" | "PROCESSING" |  null
  detailData: AllariaCxu | null
  formData: AllariaCxuFormData | null
  refetchTableData: boolean
}

const initialState: InitialState = {
  open: false,
  action: null,
  detailData: null,
  formData: null,
  refetchTableData: false,
}

const allariaCxuModal = createSlice({
  name: "allariaCxuModal",
  initialState,
  reducers: {
    onUpdateCxuModal: (state, action) => {
      return { ...state, ...action.payload }
    },
    onResetCxuModal: () => {
      return { ...initialState }
    },
  },
})

export const {
  onUpdateCxuModal,
  onResetCxuModal,
} = allariaCxuModal.actions

export default allariaCxuModal.reducer
