import {
  SOLICITUDES_CHANGE_FROM,
  SOLICITUDES_CHANGE_QUERY,
  SOLICITUDES_CHANGE_SORTING,
} from "../constants/actionTypes"

export default (
  state = {
    query: "",
    sorting: { attributeName: "creationDateTime", order: "desc" },
    from: 7,
    date: new Date(),
  },
  action
) => {
  switch (action.type) {
    case SOLICITUDES_CHANGE_QUERY:
      return { ...state, query: action.value }
    case SOLICITUDES_CHANGE_FROM:
      return { ...state, from: action.value, date: new Date() }
    case SOLICITUDES_CHANGE_SORTING:
      return {
        ...state,
        sorting: {
          attributeName: action.value,
          order:
            state.sorting.attributeName === action.value &&
            state.sorting.order === "desc"
              ? "asc"
              : "desc",
        },
      }
    default:
      return state
  }
}
