import { createSlice } from "@reduxjs/toolkit"
import {
  AllAllariaAccounts,
  Link,
  LinkActions,
} from "../components/linkages/interfaces/types"
import { DataTime } from "../interfaces/types";

interface DarumaAccount {
  id: string
  name: string
  cvu: string
}

interface AllariaAccount {
  id: string
  name: string
  cuit: string
}

interface RequestingUser {
  id: string
  fullName: string
  email: string
}
interface AllariaBalanceData {
  id: string;
  balance: number;
  expirationDateTime: DataTime;
}
interface InitialState {
    preconfirmationData: {
      allariaAccount: AllariaAccount
      darumaAccount : DarumaAccount
      requestingUser: RequestingUser
    } | null
    linkageData: Link | null
    userlinks: Link[]
    allariaAccounts: AllAllariaAccounts | null
    allariaBalance: {[key: string] : AllariaBalanceData} | null
    action: LinkActions
    open: boolean
}

const initialState: InitialState = {
  preconfirmationData: null,
  linkageData: null,
  userlinks: [],
  allariaAccounts: null,
  allariaBalance: null,
  action: "PRECONFIRMATION",
  open: false,
}

const linkageModal = createSlice({
  name: "Linkage Modal",
  initialState,
  reducers: {
    onUpdateLinkageModal: (state, action) => {
      return { ...state, ...action.payload }
    },
    onResetLinkageModal: (state) => {
      return { ...initialState, userlinks: state.userlinks }
    },
  },
})

export const { onUpdateLinkageModal, onResetLinkageModal } =
  linkageModal.actions

export default linkageModal.reducer
