import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  open: false,
}

export const roleInformationModal = createSlice({
  name: "roleInformationModal",
  initialState,
  reducers: {
    handleRoleInformationModal: (state, action) => {
      state.open = action.payload
    },
  },
})

export const { handleRoleInformationModal } = roleInformationModal.actions

export type RoleInformationModalState = typeof initialState
export default roleInformationModal.reducer
