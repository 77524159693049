import { configureStore } from "@reduxjs/toolkit"
import reducer from "./reducer"
import firebase from "firebase/app"
import firebaseConfig from "./firebaseConfig"
import "firebase/auth"
import "firebase/firestore"
import "firebase/analytics"
import * as Sentry from "@sentry/react"
import { hotjar } from "react-hotjar"

if (import.meta.env.REACT_APP_environment !== "dev") {
  hotjar.initialize(
    Number(import.meta.env.REACT_APP_HOTJAR_ID),
    Number(import.meta.env.REACT_APP_HOTJAR_SV)
  )
}

firebase.initializeApp(firebaseConfig)
firebase.firestore()
firebase.analytics()

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (
      [
        "@@reactReduxFirebase/LOGIN",
        "UPDATE_LOGIN_FIELD",
        "@@reduxFirestore/LISTENER_RESPONSE",
        "UPDATE_CHANGE_PASSWORD_FIELD",
      ].includes(action.type)
    ) {
      // Return null to not log the action to Sentry
      return null
    }

    return action
  },
  stateTransformer: (state) => {
    // Transform the state to remove sensitive information
    return {
      ...state,
      firebase: null,
      firestore: null,
    }
  },
})

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      //@redux/toolkit is offering extra protection for correct immutability and serialization of your state.
      //The previous configuration did not have this middleware
      //Solved those error with this lines
      immutableCheck: false,
      serializableCheck: false,
    }),
  enhancers: [sentryReduxEnhancer],
})

//Store type
export type RootState = ReturnType<typeof store.getState>
//Dispatch type
export type AppDispatch = typeof store.dispatch
