export const AUTHENTICATE = "AUTHENTICATE"
export const LOGOUT = "LOGOUT"
export const UPDATE_LOGIN_FIELD = "UPDATE_LOGIN_FIELD"
export const UPDATE_CHANGE_PASSWORD_FIELD = "UPDATE_CHANGE_PASSWORD_FIELD"
export const UNKNOWN = "UNKNOWN"
export const UPDATE_NEW_TRANSACTION_FIELD = "UPDATE_NEW_TRANSACTION_FIELD"
export const UPDATE_NEW_TRANSACTION = "UPDATE_NEW_TRANSACTION"
export const NEW_LINK_RESET = "NEW_LINK_RESET"
export const LINK_UPDATE_PROGRESS = "LINK_UPDATE_PROGRESS"
export const UPDATE_NEW_LINK_FIELD = "UPDATE_NEW_LINK_FIELD"
export const CONTACT_EDIT = "CONTACT_EDIT"
export const CONTACT_FILL = "CONTACT_FILL"
export const CONTACT_EDIT_RESET = "CONTACT_EDIT_RESET"
export const CONTACT_DELETE = "CONTACT_DELETE"
export const CANCEL_TRANSACTION = "CANCEL_TRANSACTION"
export const NEW_TRANSACTION_RESET = "NEW_TRANSACTION_RESET"
export const CREATE_NEW_CONTACT = "CREATE_NEW_CONTACT"
export const UPDATE_NEW_CONTACT_FIELD = "UPDATE_NEW_CONTACT_FIELD"
export const UPDATE_NEW_CONTACT_VALIDATION = "UPDATE_NEW_CONTACT_VALIDATION"
export const UPDATE_NEW_TRANSACTION_VALIDATION =
  "UPDATE_NEW_TRANSACTION_VALIDATION"
export const CREATE_NEW_TRANSACTION = "CREATE_NEW_TRANSACTION"
export const NEW_CONTACT_RESET = "NEW_CONTACT_RESET"
export const CONTACTS_CHANGE_QUERY = "CONTACTS_CHANGE_QUERY"
export const TRANSACTIONS_CHANGE_QUERY = "TRANSACTIONS_CHANGE_QUERY"
export const TRANSACTIONS_CHANGE_SORTING = "TRANSACTIONS_CHANGE_SORTING"
export const TRANSACTIONS_CHANGE_FROM = "TRANSACTIONS_CHANGE_FROM"
export const AUTH_FORM_RESET = "AUTH_FORM_RESET"
export const TRANSACTION_UPDATE_PROGRESS = "TRANSACTION_UPDATE_PROGRESS"
export const CHANGE_THEME = "CHANGE_THEME"
export const MESSAGE_SHOW = "MESSAGE_SHOW"
export const MESSAGE_DELETE = "MESSAGE_DELETE"
export const MESSAGE_FORCE_DELETE = "MESSAGE_FORCE_DELETE"
export const UPDATE_IS_ADMIN = "UPDATE_IS_ADMIN"
export const SETTINGS_EDIT = "SETTINGS_EDIT"
export const UPDATE_MASK_AMOUNT = "UPDATE_MASK_AMOUNT"
export const UPDATE_MASK_TAXES = "UPDATE_MASK_TAXES"
export const ADD_EMAIL_NOTIFICATION = "ADD_EMAIL_NOTIFICATION"
export const DELETE_EMAIL_NOTIFICATION = "DELETE_EMAIL_NOTIFICATION"
export const SOFT_RESET_TRANSACTION = "SOFT_RESET_TRANSACTION"
export const UPDATE_ACCOUNTS = "UPDATE_ACCOUNTS"
export const UPDATE_ACCOUNTS_FOR_USER = "UPDATE_ACCOUNTS_FOR_USER"

export const SOLICITUDES_CHANGE_QUERY = "SOLICITUDES_CHANGE_QUERY"
export const SOLICITUDES_CHANGE_SORTING = "SOLICITUDES_CHANGE_SORTING"
export const SOLICITUDES_CHANGE_FROM = "SOLICITUDES_CHANGE_FROM"

export const CANCEL_SOLICITUDE = "CANCEL_SOLICITUDE"
export const NEW_SOLICITUDE_RESET = "NEW_SOLICITUDE_RESET"
export const SOLICITUDE_UPDATE_PROGRESS = "SOLICITUDE_UPDATE_PROGRESS"
export const UPDATE_NEW_SOLICITUDE_FIELD = "UPDATE_NEW_SOLICITUDE_FIELD"
export const UPDATE_NEW_SOLICITUDE_VALIDATION =
  "UPDATE_NEW_SOLICITUDE_VALIDATION"
export const UPDATE_SOLICITUDE_MASK_AMOUNT = "UPDATE_SOLICITUDE_MASK_AMOUNT"
export const SOFT_RESET_SOLICITUDE = "SOFT_RESET_SOLICITUDE"

export const UPDATE_NEW_PROMISSORY_NOTE_FIELD =
  "UPDATE_NEW_PROMISSORY_NOTE_FIELD"
export const NEW_PROMISSORY_NOTE_RESET = "NEW_PROMISSORY_NOTE_RESET"
export const UPDATE_NEW_PROMISSORY_NOTE_MASK_AMOUNT =
  "UPDATE_NEW_PROMISSORY_NOTE_MASK_AMOUNT"
export const PROMISSORY_NOTES_CHANGE_QUERY = "PROMISSORY_NOTES_CHANGE_QUERY"
export const RESET_BAILOR_AND_LINKS = "RESET_BAILOR_AND_LINKS"

export const UPDATE_NEW_ECHEQ_FIELD = "UPDATE_NEW_ECHEQ_FIELD"
export const NEW_ECHEQ_RESET = "NEW_ECHEQ_RESET"
export const ECHEQS_CHANGE_QUERY = "ECHEQS_CHANGE_QUERY"

export const SET_MOCK_FIRESTORE = "SET_MOCK_FIRESTORE"

export const RESET_NEW_SERVICE_PAYMENT = "RESET_NEW_SERVICE_PAYMENT"
export const CREATE_NEW_SERVICE_PAYMENT = "CREATE_NEW_SERVICE_PAYMENT"
export const INVOICES_LIST = "INVOICES_LIST"
export const AMOUNT_TO_PAY = "AMOUNT_TO_PAY"

export const CREATE_NEW_LINKAGE = "CREATE_NEW_LINKAGE"
export const RESET_NEW_LINKAGE = "RESET_NEW_LINKAGE"
export const ALLARIA_APPROVE_NEW_LINKAGE = "ALLARIA_APPROVE_NEW_LINKAGE"

export const UPDATE_NEW_TRANSFER_REQUEST_FIELD =
  "UPDATE_NEW_TRANSFER_REQUEST_FIELD"
export const UPDATE_NEW_TRANSFER_REQUEST = "UPDATE_NEW_TRANSFER_REQUEST"
export const NEW_TRANSFER_REQUEST_RESET = "NEW_TRANSFER_REQUEST_RESET"

export const UPDATE_NEW_MASSIVE_TRANSACTION_FIELD =
  "UPDATE_NEW_MASSIVE_TRANSACTION_FIELD"
export const UPDATE_NEW_MASSIVE_TRANSACTION = "UPDATE_NEW_MASSIVE_TRANSACTION"
export const NEW_MASSIVE_TRANSACTION_RESET = "NEW_MASSIVE_TRANSACTION_RESET"

export const RESET_TRANSFER_REQUEST_SELECTED = "RESET_TRANSFER_REQUEST_SELECTED"
export const SET_TRANSFER_REQUEST_SELECTED = "SET_TRANSFER_REQUEST_SELECTED"
