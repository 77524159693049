import { useEffect } from "react"
import * as Sentry from "@sentry/react"
import EmptyTableMessage from "../ui/EmptyTableMessage/EmptyTableMessage"
import Button from "../ui/Button/Button"
import { WHATSAPP_LINK } from "../../helpers/allariaSupport"

const chunkOrModuleFailedTag = "chunk_or_module_failed"

const setWithExpiry = (key, value, ttl) => {
  const item = {
    value,
    expiry: Date.now() + ttl,
  }
  localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = (key) => {
  const itemString = window.localStorage.getItem(key)
  if (!itemString) {
    return null
  }

  const item = JSON.parse(itemString)
  const isExpired = Date.now() > item.expiry

  if (isExpired) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

const ErrorFallback = ({ error }) => {
  useEffect(() => {
    // Log error to Sentry
    Sentry.captureException(error)

    const chunkFailedError = /Loading chunk \d+ failed/
    const loadModuleError = "Failed to load module script"
    const fetchDynamicImportError =
      "Failed to fetch dynamically imported module"
    const importModuleScriptError = "Importing a module script failed"

    if (
      error?.message &&
      (chunkFailedError.test(error.message) ||
        error.message.includes(loadModuleError) ||
        error.message.includes(fetchDynamicImportError) ||
        error.message.includes(importModuleScriptError)) &&
      !getWithExpiry(chunkOrModuleFailedTag)
    ) {
      setWithExpiry(chunkOrModuleFailedTag, "true", 10_000)
      window.location.reload()
    }
  }, [error])

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100 bg-white">
      <EmptyTableMessage
        title="Lo sentimos, algo falló inesperadamente."
        text="Por favor, intentá cargar la página nuevamente o contactate con soporte"
        type="empty-moon"
        containerClassName="d-flex flex-column align-items-center justify-content-center shadow-none border-0"
      >
        <div className="w-100 d-flex flex-column flex-sm-row align-items-center justify-content-center mt-3">
          <Button
            width="10rem"
            type="button"
            buttonType="primary"
            text="Reintentar"
            click={() => window.location.replace("/")}
            className="mt-4 mr-sm-4"
          />
          <Button
            width="14rem"
            type="button"
            buttonType="secondary"
            text="Contactar con soporte"
            click={() => window.open(WHATSAPP_LINK, "_blank")}
            className="mt-4"
          />
        </div>
      </EmptyTableMessage>
    </div>
  )
}

export default ErrorFallback
