import { Nullable, User } from "../interfaces/types"
import { createSlice } from "@reduxjs/toolkit"

const initialState: Nullable<User> = {
  accounts: null,
  complianceProfile: {
    address: null,
    addressModificationDate: null,
    department: null,
    id: null,
    identificationTaxNumber: null,
    identificationTaxType: null,
    number: null,
    originCountry: null,
    pep: null,
    pepExpirationDate: null,
    pepReportedByComplianceGroup: null,
    postalCode: null,
    postalCodeModificationDate: null,
    residenceCity: null,
    residenceCountry: null,
    residenceProvince: null,
  },
  creationDateTime: null,
  documentNumber: null,
  documentType: null,
  email: null,
  id: null,
  lastName: null,
  name: null,
  phoneNumber: null,
  preferredAccountId: null,
  state: null,
  type: null,
  twoFactorAuthenticationState: null,
}

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { updateUser } = user.actions

export default user.reducer
