import { CHANGE_THEME } from "../constants/actionTypes"

export default (state = { loading: false }, action) => {
  switch (action.type) {
    case CHANGE_THEME:
      return { loading: true, theme: action.value }
    default:
      return state
  }
}
